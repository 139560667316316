import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Editor, { EditorProps, loader } from '@monaco-editor/react';
import './index.less'
import { repoExamLog } from '../../api/modules/exam';
import { useSearchParams } from 'react-router-dom';

enum LanguageEnums {
  "abap" = "abap",
  "cls" = "apex",
  "azcli" = "azcli",
  "bat" = "bat",
  "cmd" = "bat",
  "c" = "c",
  "h" = "c",
  "mligo" = "cameligo",
  "clj" = "clojure",
  "cljs" = "clojure",
  "cljc" = "clojure",
  "edn" = "clojure",
  "coffee" = "coffeescript",
  "cpp" = "cpp",
  "cc" = "cpp",
  "cxx" = "cpp",
  "hpp" = "cpp",
  "hh" = "cpp",
  "hxx" = "cpp",
  "cs" = "csharp",
  "csx" = "csharp",
  "cake" = "csharp",
  "css" = "css",
  "dart" = "dart",
  "dockerfile" = "dockerfile",
  "ecl" = "ecl",
  "fs" = "fsharp",
  "fsi" = "fsharp",
  "ml" = "fsharp",
  "mli" = "fsharp",
  "fsx" = "fsharp",
  "fsscript" = "fsharp",
  "go" = "go",
  "graphql" = "graphql",
  "gql" = "graphql",
  "handlebars" = "handlebars",
  "hbs" = "handlebars",
  "tf" = "hcl",
  "tfvars" = "hcl",
  "hcl" = "hcl",
  "html" = "html",
  "htm" = "html",
  "shtml" = "html",
  "xhtml" = "html",
  "mdoc" = "html",
  "jsp" = "html",
  "asp" = "html",
  "aspx" = "html",
  "jshtm" = "html",
  "ini" = "ini",
  "properties" = "ini",
  "gitconfig" = "ini",
  "java" = "java",
  "jav" = "java",
  "js" = "javascript",
  "es6" = "javascript",
  "jsx" = "javascript",
  "mjs" = "javascript",
  "jl" = "julia",
  "kt" = "kotlin",
  "less" = "less",
  "lex" = "lexon",
  "lua" = "lua",
  "m3" = "m3",
  "i3" = "m3",
  "mg" = "m3",
  "ig" = "m3",
  "md" = "markdown",
  "markdown" = "markdown",
  "mdown" = "markdown",
  "mkdn" = "markdown",
  "mkd" = "markdown",
  "mdwn" = "markdown",
  "mdtxt" = "markdown",
  "mdtext" = "markdown",
  "s" = "mips",
  "dax" = "msdax",
  "msdax" = "msdax",
  "m" = "objective-c",
  "pas" = "pascal",
  "p" = "pascal",
  "ligo" = "pascaligo",
  "pl" = "perl",
  "php" = "php",
  "php4" = "php",
  "php5" = "php",
  "phtml" = "php",
  "ctp" = "php",
  "dats" = "postiats",
  "sats" = "postiats",
  "hats" = "postiats",
  "pq" = "powerquery",
  "pqm" = "powerquery",
  "ps1" = "powershell",
  "psm1" = "powershell",
  "psd1" = "powershell",
  "jade" = "pug",
  "pug" = "pug",
  "py" = "python",
  "rpy" = "python",
  "pyw" = "python",
  "cpy" = "python",
  "gpy" = "python",
  "gypi" = "python",
  "r" = "r",
  "rhistory" = "r",
  "rmd" = "r",
  "rprofile" = "r",
  "rt" = "r",
  "cshtml" = "razor",
  "redis" = "redis",
  "rst" = "restructuredtext",
  "rb" = "ruby",
  "rbx" = "ruby",
  "rjs" = "ruby",
  "gemspec" = "ruby",
  "pp" = "ruby",
  "rs" = "rust",
  "rlib" = "rust",
  "sb" = "sb",
  "scala" = "scala",
  "sc" = "scala",
  "sbt" = "scala",
  "scm" = "scheme",
  "ss" = "scheme",
  "sch" = "scheme",
  "rkt" = "scheme",
  "scss" = "scss",
  "sh" = "shell",
  "bash" = "shell",
  "aes" = "sophia",
  "sol" = "sol",
  "sql" = "sql",
  "st" = "st",
  "iecst" = "st",
  "iecplc" = "st",
  "lc3lib" = "st",
  "swift" = "swift",
  "sv" = "systemverilog",
  "svh" = "systemverilog",
  "tcl" = "tcl",
  "twig" = "twig",
  "ts" = "typescript",
  "tsx" = "typescript",
  "vb" = "vb",
  "v" = "verilog",
  "vh" = "verilog",
  "xml" = "xml",
  "dtd" = "xml",
  "ascx" = "xml",
  "csproj" = "xml",
  "config" = "xml",
  "wxi" = "xml",
  "wxl" = "xml",
  "wxs" = "xml",
  "xaml" = "xml",
  "svg" = "xml",
  "svgz" = "xml",
  "oft" = "xml",
  "xsl" = "xml",
  "yaml" = "yaml",
  "yml" = "yaml",
  'json' = 'json',
  'python2' = 'python',
  'python3' = 'python'
}

interface Props extends EditorProps {
  readOnly?: boolean //只读模式
  wsUrl?: string
  onChange?: (v: any) => void //editor内容改变
  disableCopyPaste?: boolean //是否禁用复制粘贴
  [other: string]: any
}

// 中文配置
loader.config({
  "paths": { vs: 'https://cdn.bootcdn.net/ajax/libs/monaco-editor/0.43.0/min/vs' },
  "vs/nls": { availableLanguages: { "*": "zh-cn" } }
})

let CodeEditor: React.FC<Props> = (props, ref) => {

  const { readOnly = false, wsUrl, value, language, onChange, disableCopyPaste = false } = props

  const [websocket, setWebsocket] = useState<any>()

  // 需要重连
  const [reconnect, setReconnect] = useState(false)

  //当前代码
  const [codeValue, setCodeValue] = useState(value)

  //新的传入内容
  const [newValue, setNewValue] = useState(value)

  // 加载中
  const [showLoading, setShowLoading] = useState(true)

  const editorRef = useRef<any>(null);
  const monaco = useRef<any>(null);

  // websocket连接
  const handleWS = () => {
    try {
      if (!wsUrl) return
      if (websocket && websocket?.readyState === 1) {
        websocket?.close()
        setReconnect(false)
        return
      }

      const ws = new WebSocket(wsUrl);
      setWebsocket(ws)
      setReconnect(false)

      ws.onopen = function () {
        setReconnect(false)
      }

      ws.onmessage = function (res: any) {
        const { data } = res
      }

      ws.onclose = function (res: any) {
        const code = res.code
        if (code < 3000) {
          setReconnect(true)
        }
      }

      ws.onerror = function (res: any) {
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (wsUrl && reconnect) {
      handleWS()
    }
  }, [wsUrl, reconnect])

  useEffect(() => {
    if (wsUrl) {
      handleWS()
    }
  }, [wsUrl])

  useEffect(() => {
    return () => {
      if (websocket && websocket?.readyState === 1) {
        websocket?.close()
      }
    }
  }, [websocket])

  // 重置新的内容
  useEffect(() => {
    setNewValue(value)
  }, [value])

  useImperativeHandle(ref, () => ({
    getCode: () => {
      return codeValue
    },
    setCode: (value: any) => {
      setNewValue(value)
      setCodeValue(value)
    },
    // 滚动到顶部
    scrollToTop: () => {
      editorRef?.current?.setScrollPosition({ scrollTop: 0 })
    }
  }))

  const [search] = useSearchParams()
  let examRecordCode = search.get('examRecordCode') || ''

  useEffect(() => {
    if (disableCopyPaste && !showLoading && editorRef?.current && monaco?.current) {
      editorRef.current?.addCommand(monaco?.current.KeyMod.CtrlCmd | monaco?.current.KeyCode.KeyC, () => {
        repoExamLog({
          action: 'COPY',
          examRecordCode
        }).catch(() => { })
      })
      editorRef.current?.addCommand(monaco?.current.KeyMod.CtrlCmd | monaco?.current.KeyCode.KeyV, () => {
        repoExamLog({
          action: 'PASTE',
          examRecordCode
        }).catch(() => { })
      })
      editorRef.current?.addCommand(monaco?.current.KeyMod.CtrlCmd | monaco?.current.KeyCode.KeyX, () => { })

      const removableIds = ["editor.action.clipboardCutAction", "editor.action.clipboardCopyAction", "editor.action.clipboardPasteAction"]
      const contextmenu = editorRef.current?.getContribution('editor.contrib.contextmenu')
      const realMethod = contextmenu._getMenuActions

      contextmenu._getMenuActions = function () {
        const items = realMethod.apply(contextmenu, arguments)
        return items.filter(function (item: any, index: number) {
          return !(removableIds.includes(item.id) || (item.id === "vs.actions.separator" && index === 12))
        });
      };
    }
  }, [disableCopyPaste, showLoading])

  return (
    <>
      <Editor
        theme="vs-dark"
        {...props}
        value={newValue}
        language={language ? LanguageEnums[language as keyof typeof LanguageEnums] || language : 'plaintext'}
        onChange={(v: any) => {
          onChange?.(v)
          setCodeValue(v)
        }}
        onMount={(editor: any, _monaco) => {
          editorRef.current = editor;
          monaco.current = _monaco
          // 格式化
          editor.getAction('editor.action.formatDocument')?.run()
          // console.log('🚀', editor, monaco)
          // const contextMenu = editor.getContribution('editor.contrib.contextmenu')
          // console.log('🚀', contextMenu)
          // // contextMenu.dispose()

          setShowLoading(false)
        }}
        options={{
          folding: true,
          lineDecorationsWidth: 1,
          wordWrap: 'on',
          minimap: {
            enabled: false
          },
          readOnly: readOnly
        }}
      />
      {
        showLoading && (
          <div className='codeEditor-loading'>
            <div className='codeEditor-loading-in'>
              <div className='codeEditor-loading-in-line'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>加载中…</p>
            </div>
          </div>
        )
      }
    </>
  );
};

CodeEditor = forwardRef(CodeEditor as any)

export default CodeEditor